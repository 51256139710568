<template>
  <Xlayout>
    <div :class="$style.btnGroup">
      <div :class="$style.title">验证手机号</div>
      <input
        v-model="phoneNumber"
        type="tel"
        placeholder="请输入手机号"
        @input="phoneInput"
        maxlength="11"
        :disabled="isphone"
        :class="$style.phone"
      />
      <Xcaptcha :phone="phoneNumber" @change="handleCaptcha" />
      <!-- 验证码 -->
      <div :class="$style.code">
        <input
          placeholder="请输入验证码"
          type="text"
          @input="getCode"
          maxlength="4"
        />
        <Xbutton @click="sendCode" :disabled="isGetCode">
          {{ this.text }}
        </Xbutton>
      </div>
      <Xbutton :loading="isLoading" :disabled="isSubmit" @click="submit"
        >下一步</Xbutton
      >
    </div>
  </Xlayout>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import { Toast } from "vant";

import Xlayout from "../components/layout.vue";
import Xbutton from "../components/button.vue";
import Xcaptcha from "../components/captcha.vue";

import permission from "../router/permission";

// import UserService from "../service/user";
import UserVisitRecordService from "../service/user-visit-record";

export default {
  components: { Xlayout, Xbutton, Xcaptcha },
  computed: {
    ...mapState({
      //全局变量
      environment: (state) => state.environment,
      source: (state) => state.source,
      inviter: (state) => state.inviter,
      inviteSource: (state) => state.inviteSource,
      //样式参数
      primaryColor: (state) => state.application.properties.primaryColor,
      backgroundColor: (state) => state.application.properties.backgroundColor,
      //业务参数
      exhibition: (state) => state.exhibition,
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      phone: (state) => state.phone,
      visitRecord: (state) => state.visitRecord,
      businessCard: (state) => state.businessCard,
    }),
  },
  data() {
    return {
      isLoading: false,
      phoneNumber: "",
      isGetCode: false,
      text: "获取验证码",
      time: 60,
      isphone: false,
      isSubmit: true,
      verificationCode: "",
    };
  },
  methods: {
    ...mapMutations({
      setPhone: "setPhone",
    }),
    ...mapActions({
      addAuthorization: "addAuthorization",
      getVisitRecord: "getVisitRecord",
    }),
    phoneInput: function ({ target }) {
      this.phoneNumber = target.value;
    },
    getCode: function ({ target }) {
      this.verificationCode = target.value;
    },
    // 监听状态
    handleCaptcha: function (type) {
      this.isGetCode = !type;
      if (type) {
        this.sendCode();
      }
    },
    setCodeStatus: function () {
      // 禁用发送
      this.isGetCode = true;
      let _inter = null;
      const set = () => {
        this.time--;
        // 时间到
        if (this.time === 0) {
          this.time = 60;
          this.text = "获取验证码";
          this.isGetCode = false; // 开始发送
          this.isphone = false;
          // this.isSubmit = true;
          clearTimeout(_inter);
        } else {
          this.text = this.time + "s";
          _inter = setTimeout(set, 1000);
        }
      };
      set();
    },
    // 发送验证吗
    sendCode: async function () {
      this.isGetCode = true;
      this.isphone = true;
      this.text = "发送中...";

      try {
        const result = await window.axios({
          url: `/application/${this.application.id}/authorization/phone-verification-code`,
          method: "POST",
          data: { number: this.phoneNumber },
        });
        // 发送成功
        if (result.status === 201) {
          this.setCodeStatus();
          this.isSubmit = false;
        } else {
          this.isGetCode = false;
          this.isphone = false;
          this.isSubmit = true;
          this.text = "获取验证码";
          Toast({ type: "fail", message: "请稍后再试" });
        }
      } catch (err) {
        console.log(err);
        this.isGetCode = false;
        this.isphone = false;
        this.isSubmit = true;
        this.text = "获取验证码";
      }
    },
    // 提交
    submit: async function () {
      try {
        this.isLoading = true;
        const phoneParam = { number: this.phoneNumber };

        const params = {
          data: {
            type: "PHONE_VERIFICATION_CODE",
            params: {
              phone: phoneParam,
              verificationCode: this.verificationCode,
            },
          },
        };

        console.log(this.account.id);

        // 存在Account则携带Account
        if (this.account.id) {
          params.data.account = {
            id: this.account.id,
          };
          params.data.user = {
            id: this.user.id,
          };
        }

        await this.addAuthorization(params);

        await this.getVisitRecord();

        permission.checkVisitRecord(
          this.visitRecord,
          async () => {
            //未验证手机号
            const params = {
              properties: {
                applicationId: this.application.id,
                environment: this.environment,
                source: this.source,
              },
              progress: "VERIFIED_PHONE",
              phone: {
                id: this.phone.id,
              },
              exhibition: {
                id: this.exhibition.id,
              },
              application: {
                id: this.application.id,
              },
            };
            //如果存在邀请人
            if (this.inviter) {
              //且与本地用户不同则有人邀请
              if (this.user.id !== this.inviter) {
                // UserService

                params.properties.inviter = this.inviter;
                params.properties.inviteSource = this.inviteSource;
                params.inviter = {
                  id: this.inviter,
                };
              }
            }
            //创建参观记录
            await UserVisitRecordService.addUserVisitRecord(this.user, params);
            //更新本地参观记录
            await this.getVisitRecord();
            //跳转完善信息
            this.$router.push({ path: "/info" });
          },
          () => {
            // 未完善信息
            this.$router.push({ path: "/info" });
          },
          () => {
            //未填写问卷
            this.$router.push({ path: "/questionnaire" });
          },
          () => {
            //预登记完成
            this.$router.push({ path: "/card" });
          }
        );
      } catch (error) {
        console.log(error.response);
        if (error.code) {
          switch (error.code) {
            case "404-visitRecord":
              //兼容之前的逻辑
              this.$router.push({ path: "/verification" });
              break;
            default:
              break;
          }
        } else if (error.response) {
          Toast({ type: "fail", message: error.response.data.message });
        }
        this.isLoading = false;
      } finally {
        console.log("finally");
      }
    },
  },
  mounted() {
    console.log(this.businessCard.value);

    if (
      this.businessCard &&
      this.businessCard.value &&
      this.businessCard.value.PsnMobile != ""
    ) {
      this.phoneNumber = this.businessCard.value.PsnMobile;
    }
  },
};
</script>

<style lang="less" module>
.btnGroup {
  width: 100%;
  box-sizing: border-box;
  padding: 25px 15px;
  height: 100%;
  flex: 1;
  box-sizing: border-box;

  input::placeholder {
    color: #cecece;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
    //text-align: center;
  }

  .phone {
    overflow: hidden;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    //border-radius: 5px;
    font-size: 15px;
    padding: 15px 10px;
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc;
    background: rgba(255, 255, 255, 0);
    margin: 15px 0;
  }

  .code {
    display: flex;
    margin: 15px 0;
    justify-content: space-between;

    input {
      width: 200px;
      margin-right: 15px;
      flex-shrink: 0;
      outline: none;
      border: none;
      border-bottom: 1px solid #ccc;
      background: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      height: 44px;
      //border-radius: 5px;
      padding: 15px 10px;
      font-size: 15px;
    }
  }
}
</style>
